import { Carousel } from "antd";
import { Link } from "react-router-dom";
function Home() {
  return (
    <>
      <div className="center0 mt20 pb10">
        <img className="logo" src="./img/ico.png" alt="华视界" />
        <h1>更多内容 更多享受</h1>
        <div className="font12">
          全球直播电视台、电影、电视剧、综艺、体育赛事...全都有
        </div>

        <Carousel className="carousel" autoplay>
          <div>
            <img className="phone" src="./img/phone.png" alt="pbone" />
          </div>
          <div>
            <img className="tv" src="./img/tv.png" alt="tv" />
          </div>
        </Carousel>
        <div className="font4">影视直播 &nbsp;&nbsp;&nbsp;尽在华视界APP</div>
        <div className="font2"> </div>
        <Link to={"/tv"}>
          <button className="btn  mt6">电视盒子TV安装</button>
        </Link>
        <Link to={"/ios"}>
          <button className="btn mt10">手机下载安装</button>
        </Link>
        <Link to={"/pc"}>
          <button className="btn mt10">电脑PC使用</button>
        </Link>
      </div>
    </>
  );
}

export default Home;
