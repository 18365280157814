import copy from "copy-to-clipboard";
import { message } from "antd";
function Pc() {
  return (
    <div className="app">
      <button className="back" onClick={() => window.history.back()}>
        返回
      </button>
      <div className="top">
        <div className="mt10 center0">
          <img src="./img/detail-null.png" alt="" />
          <div className="mt4 fontWhite"></div>
          <div className="bar fontWhite mt10">电脑浏览器访问</div>
          <div className="url">www.hlove.tv</div>
          <div className="mt10">
            <button
              className="btn"
              onClick={() => {
                copy("https://hlove.tv");
                message.success("复制成功");
              }}
            >
              复制链接
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pc;
