import { message } from "antd";
import copy from "copy-to-clipboard";

function Tv() {
  function isWeChatBrowser() {
    const userAgent = navigator.userAgent.toLowerCase();
    // 检查用户代理字符串中是否包含 "micromessenger"
    return userAgent.indexOf("micromessenger") !== -1;
  }
  const download = () => {
    if (isWeChatBrowser()) {
      copy("https://static.ymedium.top/app/tv.apk");
      message.info("暂不支持微信浏览器。已复制链接，请前往其他浏览器下载");
    } else {
      window.location.href = "https://static.ymedium.top/app/tv.apk";
    }
  };
  return (
    <>
      <button className="back" onClick={() => window.history.back()}>
        返回
      </button>
      <div className="center0 mt10 pb10">
        <img className="logo" src="./img/ico.png" alt="华视界" />
        <h1>更多内容 更多享受</h1>
        <div className="font12">
          全球直播电视台、电影、电视剧、综艺、体育赛事...全都有
        </div>
        <img className="tv" src="./img/tv.png" alt="pbone" />

        <div className="gap">
          <div className="font3">电视TV客户端使用教程</div>
          <div className="font2">步骤一:下载安装包拷贝到U盘</div>
          <div className="font2">步骤二:在电视盒子文件管理打开U盘</div>
          <div className="font2">步骤三:找到安装包点击安装</div>
        </div>
        <button className="btn" onClick={download}>
          下载电视TV安装包
        </button>
      </div>
    </>
  );
}

export default Tv;
