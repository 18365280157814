import { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useRoutes } from "react-router";
import Ios from "./Ios.js";
import Tv from "./Tv";
import Home from "./Home.js";
import Pc from "./Pc.js";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" extra element={<Home />}></Route>
        <Route path="/ios" element={<Ios />}></Route>
        <Route path="/tv" element={<Tv />}></Route>
        <Route path="/pc" element={<Pc />}></Route>
      </Routes>
    </>
  );
}

export default App;
