import { useEffect, useState } from "react";
import "./App.css";
import copy from "copy-to-clipboard";
import { message } from "antd";

function Ios() {
  const [isIos, setIsIos] = useState(false);
  function detectDeviceType() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsIos(true);
    }

    // Check for Android
    if (/android/i.test(userAgent)) {
    }

    // Default return value
  }


  function isWeChatBrowser() {
    const userAgent = navigator.userAgent.toLowerCase();
    // 检查用户代理字符串中是否包含 "micromessenger"
    return userAgent.indexOf("micromessenger") !== -1; 
  }
  const download = () => {

    if (isWeChatBrowser()) {
      copy("https://static.ymedium.top/app/phone.apk");
      message.info("暂不支持微信浏览器。已复制链接，请前往其他浏览器下载");
    } else {
      window.location.href = "https://static.ymedium.top/app/phone.apk";
    }
  };


  

  useEffect(() => {
    detectDeviceType();
  }, []);
  return (
    <>
      <button className="back" onClick={() => window.history.back()}>
        返回
      </button>
      {isIos ? (
        <div className="app">
          <div className="top">
            <div className="mt10 center0">
              <img src="./img/detail-null.png" alt="" />
              <div className="mt4 fontWhite">暂不支持苹果手机,敬请期待</div>
              <div className="bar fontWhite">前往电脑端观看</div>
              <div className="url">www.hlove.tv</div>
              <div className="mt10">
                <button
                  className="btn"
                  onClick={() => {
                    copy("https://hlove.tv");
                    message.success("复制成功");
                  }}
                >
                  复制链接
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="center0 mt10">
          <img className="logo" src="./img/ico.png" alt="华视界" />
          <h1>更多内容 更多享受</h1>
          <div className="font12">
            全球直播电视台、电影、电视剧、综艺、体育赛事...全都有
          </div>
          <img className="phone" src="./img/phone.png" alt="pbone" />
          <h1>安卓手机客户端</h1>
          <div className="font2">下载完成后安装即可使用</div>
          <button
            className="btn mt10"
            onClick={download
            }
          >
            立即下载
          </button>
        </div>
      )}
    </>
  );
}

export default Ios;
